import ssm from 'simplestatemanager';

let panels;
let timeout = null;
const speed = 100;
let slides  = document.querySelector(".glide__slides");

const onMouseOver = function(e) {
    [].forEach.call(projects, function(p){
        p.classList.remove('hover');
    });
    e.target.classList.add('hover');
};

const projects = document.querySelectorAll('.project');

[].forEach.call(projects, function(p) {
    p.addEventListener('mouseenter', onMouseOver, false)
});

window.addEventListener('scroll', function (event) {
    [].forEach.call(projects, function(p){
        if (isInViewport(p))
            p.classList.add('hover');
        else
            p.classList.remove('hover');
    });
}, false);

const isInViewport = function (elem) {
    const distance = elem.getBoundingClientRect();
    return (
        distance.top >= 0 &&
        distance.left >= 0 &&
        distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

ssm.addState({
    id: "mobile",
    query: "(min-width: 700px)",
    onEnter: function() {
        slides.classList.remove("flex-column")
        panels = new Glide('#carousel',
            {
                type: 'slider',
                perView: 4,
                focusAt: 'center',
                breakpoints: {
                    960: {
                        perView: 2
                    },
                    1300: {
                        perView: 3
                    }
                },
                startAt: 2
            }
        );
        panels.on(['run.after', 'mount.after'], function(e){
            let elem = document.querySelector(".glide__slide--active .project");
            if (elem)
                onMouseOver({"target": elem});
        });

        panels.mount();
    },
    onLeave: function() {
        slides.classList.add("flex-column")
        panels.destroy()
    }
});

document.querySelector('#carousel').addEventListener('wheel', (function(event) {
    if (timeout !== null) {
        event.preventDefault();
        return false;
    }

    let delta = event.detail ? event.detail * (-120) : (event.wheelDelta ? event.wheelDelta : (event.deltaY ? event.deltaY  * (-120) : 0));

    let scrollDown = delta < 0;
    let canScroll = !document.querySelector(".glide__slides.flex-column");

    if (canScroll) {
        timeout = setTimeout(function(){timeout = null;}, speed);
        event.preventDefault();
        if (scrollDown) {
            panels.go("<");
        } else {
            panels.go(">");
        }
        return false;
    }

    return true;
}));